<template>
<div>
  <ul class="menu-ul">
    <li v-for="(item, index) in category" :key="index" :class="{'d-none' : item.id == 90}">
      <template v-if="item.children.data.length != 0">
        <div class="menu-option" :class="{active: menuIndex == index}" @click="toggleMenu(index)">
          <span class="menu-title">{{ item.name }}</span>
          <i class="fas fa-chevron-down fa-xs" v-if="item.children.data.length != 0"></i>
        </div>
        <ul :class="{active: menuIndex == index}">
          <template v-for="(subItem, subIndex) in item.children.data">
            <template v-if="subItem.children.data.length != 0">
              <li class="inline" :key="subIndex">
                <div class="menu-option" :class="{active: subMenuIndex == subIndex}" @click="toggleSubMenu(subIndex)">
                  <span class="option-title option-block">{{ subItem.name }}</span>
                  <i class="fas fa-chevron-down fa-xs sub-dropdown" v-if="item.children.data.length != 0"></i>
                </div>
                <ul :class="{active: subMenuIndex == subIndex}">
                  <li>
                    <router-link :to="{ path: pagePath(subItem.id), query: { category: subItem.id }}">
                      <span class="option-link option-block">{{ subItem.name }}總覽</span>
                    </router-link>
                  </li>
                  <li v-for="(subSubItem, subSubIndex) in subItem.children.data" :key="subSubIndex">
                    <router-link :to="{ path: pagePath(subSubItem.id), query: { category: subSubItem.id }}">
                      <span class="option-link option-block">{{ subSubItem.name }}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
            </template>
            <template v-else>
              <li :key="subIndex">
                <router-link :to="{ path: pagePath(subItem.id, item.id), query: { category: subItem.id }}">
                  <span class="option-link option-block">{{ subItem.name }}</span>
                </router-link>
              </li>
            </template>
          </template>
        </ul>
      </template>
      <template v-else>
        <router-link :to="{ path:  pagePath(item.id, item.id), query: { category: item.id }}"><span class="menu-title menu-option">{{ item.name }}</span></router-link>
      </template>
    </li>
  </ul>
</div>
</template>

<script>
  export default {
    created() {
      window.addEventListener('click', this.close)
    },
    beforeDestroy() {
      window.removeEventListener('click', this.close)
    },
    methods: {
      close(e) {
        if (! this.$el.contains(e.target) ){
          this.$store.commit('setMenu',{
            key: `${this.space}.main.menuIndex`,
            value: -1
          })
          this.$store.commit('setMenu',{
            key: `${this.space}.main.subMenuIndex`,
            value: -1
          })
        }
      }
    }
  }
</script>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
  space: {
    type: String,
    default: "header",
  },
});
const store = useStore()
const category = computed(() => store.state.category)
const menu = computed(() => store.state.menu)
const menuIndex = computed(() => store.state.menu[props.space].main.menuIndex)
const subMenuIndex = computed(() => store.state.menu[props.space].main.subMenuIndex)
const toggleMenu = (index) => {
  store.commit('setMenu',{
    key: `${props.space}.main.menuIndex`,
    value: (index === menuIndex.value)? -1:index
  })
}
const toggleSubMenu = (index) => {
  store.commit('setMenu',{
    key: `${props.space}.main.subMenuIndex`,
    value: (index === subMenuIndex.value)? -1:index
  })
}

const pagePath = (id, parent_id) => {
  if (id == 56) {
    return '/expert'
  }
  if (parent_id == 330) {
    return '/layout'
  }
  return '/article'
}

</script>

<style lang="scss" scoped>

</style>