<template>
<div>
  <ul class="menu-ul">
    <li @click="toggleMenu(1)">
        <div class="menu-option">
          <span class="ul-title">査酒款</span><i class="fas fa-chevron-down fa-xs"></i>
        </div>
        <ul :class="{active: menuIndex == 1}">
            <li v-for="(item, index) in productTypes" :key="index">
              <router-link :to="{ path: '/product', query: { type: item.id }}">
                <span class="option-link option-block">{{ item.name }}</span>
              </router-link>
            </li>
        </ul>
    </li>
    <li>
      <div class="menu-option">
        <router-link to="/subscription">訂雜誌</router-link>
      </div>
    </li>
    <li>
      <div class="menu-option">
        <a href="https://drive.google.com/drive/folders/1GyhRy0bsAYL1La1fHO4z7QbMZ11uKVgh?usp=sharing" target="_blank">進口量統計表</a>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
  export default {
    created() {
      window.addEventListener('click', this.close)
    },
    beforeDestroy() {
      window.removeEventListener('click', this.close)
    },
    methods: {
      close(e) {
        if (! this.$el.contains(e.target) ){
          this.$store.commit('setMenu',{
            key: `${this.space}.sub.menuIndex`,
            value: -1
          })
        }
      }
    }
  }
</script>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
  space: {
    type: String,
    default: "header",
  },
});
const productTypes = computed(() => store.state.productTypes)
const menuIndex = computed(() => store.state.menu[props.space].sub.menuIndex)
const toggleMenu = (index) => {
  store.commit('setMenu',{
    key: `${props.space}.sub.menuIndex`,
    value: (index === menuIndex.value)? -1:index
  })
}
</script>

<style lang="scss" scoped>
</style>